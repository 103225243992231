.procedure-wrapper {
  min-width: 1200px;
  height: 100%;
  .anticon-arrow-down,
  .anticon-caret-down {
    color: #77ff3b;
  }
}

.procedure-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 72px;
  box-shadow: 2px 1px 8px 0px rgba(0, 0, 0, 0.2);
  .right {
    width: 350px;
  }
}

.procedure-main {
  // display: flex;
  min-height: calc(100% - 72px);
  position: relative;
  overflow: hidden;
}

.procedure-control {
  display: flex;
  flex-direction: column;
  width: 262px;
  height: 100%;
  box-shadow: 2px 6px 8px 0px rgba(0, 0, 0, 0.2);
  padding: 20px;
  position: absolute;
  left: 0;
  top: 0;
  transition: all 200ms;
  &.hide {
    transform: translateX(-100%);
  }
  .show-list-icon {
    right: -16px;
  }
}

.show-list-icon {
  position: absolute;
  top: 20%;
  cursor: pointer;
}

.procedure-content {
  // flex: 1;
  padding: 40px 0;
  margin-left: 262px;
  margin-right: 350px;
  transition: all 200ms;
  overflow: auto;
  &.full-left {
    margin-left: 0;
  }
  &.full-right {
    margin-right: 0;
  }
}

.procedure-config {
  width: 350px;
  box-shadow: -2px 6px 8px 0px rgba(0, 0, 0, 0.2);
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  transition: all 200ms;
  &.hide {
    transform: translateX(100%);
  }
  .show-list-icon {
    left: -16px;
  }
}

.add-btn {
  margin-top: 20px;
  width: 100%;
}

.procedure-list {
  list-style: none;
  padding: 0;
  overflow: auto;
  margin-bottom: 0;
  flex: 1;
  li {
    margin: 20px 0;
    line-height: 40px;
    color: #999;
    border-radius: 3px;
    text-align: center;
    cursor: pointer;
    border: 1px dotted #ccc;
    &.current {
      background: #1890ff;
      color: #fff;
    }
  }
  li:last-child {
    margin-bottom: 0;
  }
}

.node-item-wrap {
  .node-item {
    border-bottom: 1px solid #e5e5e5;
    min-height: 100px;
    padding: 20px;
    .item-title {
      display: block;
      margin-bottom: 5px;
    }
    .anticon-edit {
      cursor: pointer;
    }
    .node-checkbox {
      display: flex;
      flex-direction: column;
      >.ant-checkbox-wrapper {
        margin-left: 0;
      }
    }
    .corp-range {
      .ant-tag {
        margin-bottom: 4px;
      }
    }
  }

  .node-item:last-child {
    border-bottom: none;
  }
}

.temp {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.node-wrap {
  display: flex;
  flex-direction: column;
}

.node {
  min-width: 100px;
  padding: 0 2px;
  height: 66px;
  line-height: 66px;
  text-align: center;
  background: #ccc;
  border-radius: 5px;
  transition: all 250ms ease-out;
  &.active {
    background: #5082fd;
    color: #fff;
  }
  &.current {
    box-shadow: 0 5px 15px rgba(20, 20, 20, 0.5);
  }
}

.node-line-vertical {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 8px;
  .vertical-line {
    width: 2px;
    height: 60px;
    background: rgba(119, 255, 59, 1);
  }
  .anticon {
    color: rgba(119, 255, 59, 1);
    position: relative;
    top: -4px;
  }
}

.tag-disable {
  background: #ccc!important;
  color: #666!important;
  cursor: no-drop!important;
  &:hover {
    opacity: 1!important;
  }
}

.control-btn {
  visibility: hidden;
  text-align: center;
  width: 262px;
  &.show {
    visibility: visible;
  }
  .anticon {
    font-size: 26px;
    margin: 0 20px;
    cursor: pointer;
  }
}

.loading-full {
  width: 100vw;
  height: 100vh;
  background: rgba(255, 255, 255, 0);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 5000;
  transition: background 500ms;
  cursor: wait;
  &.showLoading {
    background: rgba(255, 255, 255, 0.5);
  }
}

.branch-wrap {
  min-width: 50%;
  .anticon-arrow-down {
    color: rgba(119, 255, 59, 1);
  }
  .row-line {
    display: flex;
    // border-top: 2px solid rgba(119, 255, 59, 1);
    justify-content: space-between;
    padding: 0 35px;
    align-items: flex-start;
    .node-line {
      height: 2px;
      background: rgba(119, 255, 59, 1);
      flex: 1;
    }
    .anticon {
      color: rgba(119, 255, 59, 1);
      position: relative;
    }
    .anticon:nth-child(1) {
      left: 8px;
    }
    // .anticon:nth-child(3) {
    //   left: -8px;
    // }
  }
  .row-line-one {
    text-align: center;
    padding: 0 51px;
    .anticon-arrow-down {
      position: relative;
      top: -5px;
    }
  }
  .line-border {
    display: block;
    height: 20px;
    border: 2px solid rgba(119, 255, 59, 1);
    border-top: none;
  }
  .branch {
    display: flex;
    justify-content: space-between;
    .branch-node {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin: 0 2px
    }
    .line-more {
      display: block;
      width: 2px;
      flex: 1;
      background: rgba(119, 255, 59, 1);
    }
  }
}

.temp-3 {
  .node-branch {
    display: flex;
  }
  .node-row-3-first {
    display: flex;
  }
  .branch-box-wrap {
    display: flex;
    .branches-wrap {
      display: flex;
    }
  }
  .center {
    justify-content: center;
  }
  .branch-box-wrap-1 {
    display: flex;
  }
  .branch-top {
    display: flex;
  }
  .branch-bottom {
    display: flex;
  }
  .blank-node {
    > span {
      display: block;
      width: 100px;
      height: 66px;
      margin: 0 15px;
      text-align: center;
      line-height: 66px;
      box-sizing: content-box;
      // background: #eee;
    }
  }
  .node-wrap {
    padding: 0 15px;
    position: relative;
  }
  .flex {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .flex-1 {
    flex: 1;
  }
  .branch-line {
    flex: 1;
    width: 2px;
    background: #77ff3b;
    
  }
  .top-line,
  .plane-line {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 0 64px;
    .node-line {
      width: 100%;
      height: 2px;
      line-height: 1;
      background: #77ff3b;
      background-clip: content-box;
    }
    .anticon-arrow-down {
      position: relative;
      top: -2px;
    }
  }
  .top-line {
    padding: 0;
    &.left {
      align-items: flex-start;
      transform: translateX(50%);
      .anticon-arrow-down {
        left: -6px;
      }
    }
    &.right {
      align-items: flex-end;
      transform: translateX(-48.5%);
      .anticon-arrow-down {
        left: 6px;
      }
    }
  }
  .node-branch-wrap {
    > .plane-line {
      width: 846px;
      .anticon-arrow-down {
        left: 32px;
      }
    }
    > .normal-line {
      width: calc(100% - 15px);
      .anticon-arrow-down {
        left: -1px;
      }
    }
  }
  .node-line-vertical {
    padding-top: 0;
  }
}



